import React from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  let { pathname } = useLocation();

  return (
    <>
      <header>
        <div className="container">
          <nav className="navbar navbar-dark bg-transparenet">
            <a className="navbar-brand" href="/">
              {pathname === "/privacy" ? (
                <img
                  src="/img/logo_privacy.svg"
                  alt="logo"
                  className="img-fluid"
                />
              ) : pathname === "/terms" ? (
                <img
                  src="/img/logo_privacy.svg"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img src="/img/logo.svg" alt="logo" className="img-fluid" />
              )}
            </a>
            {pathname === "/privacy" ? (
            <ul className="header_links">
              <li>
                <a
                  className="navbar-text d-none d-sm-inline-block" style={{color:"#000"}}
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="navbar-text d-none d-sm-inline-block" style={{color:"#000"}}
                  href="/terms"
                >
                  Terms of Services
                </a>
              </li>
            </ul>
             ) : pathname === "/terms" ? (
              <ul className="header_links">
              <li>
                <a
                  className="navbar-text d-none d-sm-inline-block" style={{color:"#000"}}
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="navbar-text d-none d-sm-inline-block" style={{color:"#000"}}
                  href="/terms"
                >
                  Terms of Services
                </a>
              </li>
            </ul>
            ) : (
            <ul className="header_links">
              <li>
                <a
                  className="navbar-text d-none d-sm-inline-block"
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="navbar-text d-none d-sm-inline-block"
                  href="/terms"
                >
                  Terms of Services
                </a>
              </li>
            </ul>
            )}
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;

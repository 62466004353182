import React from 'react';
import { Route, useParams } from "react-router-dom";

function PrivacyPolicy() {
    
  return (
    <>
      <div className="privacy_content">
        <div name="termly-embed" data-id="9cc00fc2-19cc-444c-b6b2-3f5344b64f00" data-type="iframe"></div>
      </div>
 
    </>
  )
}

export default PrivacyPolicy
import React from 'react'

function TermConditions() {



  return (
    <>
    <div className="termandcondition_content">
        <div name="termly-embed" data-id="343bc4be-dd7d-406c-8d76-c89658a53398" data-type="iframe"></div>
    </div>
    </>
  )
}

export default TermConditions
import React from 'react'

function Home() {
  return (
    <>
        <div className="my-auto coming_soon">
            <div className="container">
                <div className="coming-soon-text">
                    <h1 className="page-title text-white text-center">Saving you <span>time, </span>by eliminating your
                        tabs.
                    </h1>
                    <p className="text-center">Appstack is a all-in-one dashboard that allows you to easily monitor the key metrics of your most critical Shopify Apps and Integrations.</p>
                </div>
            </div>
            <div className="form_container">
                <div className="klaviyo-form-SQUgtr desktop_form"></div>
                <div className="klaviyo-form-UpHnDy mobile_form"></div>
            </div>
            <div className="container bg_image">
                <img src="img/coming-dashboad.png" className="img-fluid desktop_img"/>
                <img src="img/coming-dashboadmobile.png" className="img-fluid mobile_img"/>
            </div>    
           
        </div>
    </>
  )
}

export default Home
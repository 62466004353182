import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Pages/Header';
import Home from './Pages/Home';
import PrivacyPolicy from './Pages/Privacy-Policy';
import TermConditions from './Pages/Term-Conditions';

function App() {
  return (
    <>
    <BrowserRouter>
    <div className="coming-soon min-vh-100 d-flex flex-column" style={{background: window.location.pathname == "/" ? "#18113C" : "#fff"}}>
      <Header />
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<TermConditions />} />

      </Routes>
      </div>
    </BrowserRouter>

    </>
  );
}

export default App;
